import { parse } from 'date-fns';
import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trans } from '@spotahome/soyuz/client';

import FormInput from '../FormInput';

import './FormDate.pikaday.module.scss'; // Do not delete it

import {
  DISPLAYED_DATE_FORMAT,
  formatDisplayDate,
  formatDate,
  parseDate
} from '../utils/dateUtils';

import styles from './FormDate.module.scss';

const TRANS_DELIMITER = '_';

export const useFormDate = ({
  id,
  title,
  name,
  bold,
  dataTest,
  dataTestCalendar,
  onChange,
  onCalendarOpen,
  onCalendarClose,
  disabled,
  calendarBordered,
  placeholder,
  absolutePosition,
  value,
  renderCalendarContent,
  required,
  hasError,
  isCalendarOpen: isCalendarOpenProp,
  calendarPosition,
  minDate,
  maxDate,
  disableDayFn
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(isCalendarOpenProp);

  const pickerRef = useRef(null);
  const displayRef = useRef(null);
  const triggerRef = useRef(null);
  const calendarContainerRef = useRef(null);
  const rawDate = pickerRef.current ? pickerRef.current.getDate() : value;
  const humanReadableDate = rawDate ? formatDisplayDate(rawDate) : '';

  const handleCalendarOpen = useCallback(() => {
    if (disabled) {
      return;
    }
    setIsCalendarOpen(true);
    pickerRef.current.gotoDate(parseDate(value || calendarPosition));
    onCalendarOpen();
  }, [calendarPosition, disabled, onCalendarOpen, value]);

  const handleCalendarClose = useCallback(() => {
    setIsCalendarOpen(false);
    onCalendarClose();
  }, [onCalendarClose]);

  const handleDateSelect = useCallback(
    date => {
      const formattedDate = formatDate(date);
      onChange({ value: formattedDate, name });
    },
    [name, onChange]
  );

  useEffect(() => {
    // eslint-disable-next-line node/global-require
    const Pikaday = require('pikaday');
    pickerRef.current = new Pikaday({
      container: calendarContainerRef.current,
      field: displayRef.current,
      trigger: triggerRef.current,
      firstDay: 1,
      i18n: {
        previousMonth: trans('pikaday.label.previous_month'),
        nextMonth: trans('pikaday.label.next_month'),
        months: trans('pikaday.label.months').split(TRANS_DELIMITER),
        weekdays: trans('pikaday.label.weekdays').split(TRANS_DELIMITER),
        weekdaysShort: trans('pikaday.label.weekdays_short').split(
          TRANS_DELIMITER
        ),
        dateInPlaceholder: trans('pikaday.label.date_in'),
        dateOutPlaceholder: trans('pikaday.label.date_out')
      },
      disableDayFn,
      minDate,
      maxDate,
      onClose: handleCalendarClose,
      onOpen: handleCalendarOpen,
      onSelect: handleDateSelect,
      parse: s => (s ? parseDate(s, DISPLAYED_DATE_FORMAT) : null),
      toString: v => formatDisplayDate(v)
    });
    return () => pickerRef.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableDayFn, handleCalendarClose, handleCalendarOpen, handleDateSelect]);

  useEffect(() => {
    setIsCalendarOpen(isCalendarOpenProp);
    if (isCalendarOpenProp) {
      pickerRef.current.show();
    } else {
      pickerRef.current.hide();
    }
  }, [isCalendarOpenProp]);

  useEffect(() => {
    pickerRef.current.setMinDate(minDate);
  }, [minDate]);

  useEffect(() => {
    pickerRef.current.setMaxDate(maxDate);
  }, [maxDate]);

  useEffect(() => {
    if (value) {
      pickerRef.current.setDate(parseDate(value), true);
    }
  }, [value, pickerRef]);

  const getClassNames = () => ({
    calendarWrapper: classNames(
      styles['formdate__calendar-wrapper'],
      {
        [styles['formdate__calendar-wrapper--absolute']]: absolutePosition,
        [styles['formdate__calendar-wrapper--hidden']]: !isCalendarOpen,
        [styles['formdate__calendar-wrapper--bordered']]: calendarBordered
      },
      'pika-single' // don't remove: this prevents calendar being closed when clicking elements inside the calendar
    ),
    calendar: classNames(styles.formdate__calendar),
    input: classNames(styles.formdate__input, {
      [styles['formdate__input--disabled']]: disabled,
      [styles['formdate__input--selected']]: isCalendarOpen,
      [styles['formdate__input--not-selected']]: !isCalendarOpen,
      [styles['formdate__input--with-title']]: !!title,
      [styles['formdate__input--with-error']]: hasError
    })
  });

  const cns = getClassNames();

  const renderCalendar = () => (
    <div translate="no" className={cns.calendarWrapper}>
      <div data-test={dataTestCalendar} ref={calendarContainerRef} />
      {isCalendarOpen ? renderCalendarContent() : null}
    </div>
  );

  const renderInput = () => (
    <div className={cns.input} ref={triggerRef}>
      <FormInput
        id={id}
        dataTest={dataTest}
        placeholder={placeholder}
        title={title}
        readOnly
        bold={bold}
        name={name}
        innerRef={displayRef}
        type="text"
        value={humanReadableDate}
        disabled={disabled}
        required={required}
        hasError={hasError}
      />
    </div>
  );

  return {
    humanReadableDate,
    selectedDate: rawDate ? formatDate(rawDate) : '',
    displayRef,
    triggerRef,
    renderInput,
    renderCalendar,
    getClassNames
  };
};

const FormDate = ({ children, ...props }) => {
  const {
    renderInput,
    renderCalendar,
    getClassNames,
    displayRef,
    triggerRef,
    humanReadableDate,
    selectedDate
  } = useFormDate(props);
  const cns = getClassNames();

  return children ? (
    children({
      renderInput,
      renderCalendar,
      displayRef, // ref where the date will be displayed (any click outside of it will close the calendar)
      triggerRef, // calendar will open when this trigger ref is clicked
      humanReadableDate,
      selectedDate
    })
  ) : (
    <div className={cns.container}>
      {renderInput()}
      {renderCalendar()}
    </div>
  );
};

FormDate.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onCalendarOpen: PropTypes.func,
  onCalendarClose: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  calendarBordered: PropTypes.bool,
  absolutePosition: PropTypes.bool,
  bold: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]),
  name: PropTypes.string,
  dataTest: PropTypes.string,
  renderCalendarContent: PropTypes.func,
  title: PropTypes.string,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  isCalendarOpen: PropTypes.bool,
  calendarPosition: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disableDayFn: PropTypes.func
};

FormDate.defaultProps = {
  id: 'form-date',
  name: 'form-date',
  dataTest: 'form-date',
  placeholder: '',
  onChange: () => {},
  onCalendarOpen: () => {},
  onCalendarClose: () => {},
  disabled: false,
  calendarBordered: false,
  absolutePosition: false,
  bold: false,
  value: '',
  children: null,
  renderCalendarContent: () => {},
  required: false,
  hasError: false,
  title: '',
  isCalendarOpen: false,
  calendarPosition: '',
  minDate: '',
  maxDate: '',
  disableDayFn: () => {}
};

export default FormDate;
