import { lazy, Suspense } from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import FormInput from '../FormInput';

import styles from './FormPhoneInput.module.scss';

import './FormPhoneInputOverwrite.module.scss';

const cx = classNames.bind(styles);

const LazyPhoneInput = lazy(() => import('@spotahome/react-phone-input-2'));

const FormPhoneInput = ({
  autoFormat = true,
  dataTest = 'form-phone-input',
  disabled = false,
  disableInitialCountryGuess = false,
  disableDropdown = false,
  disableSearchIcon = true,
  hasError = false,
  id = 'form-phone-input',
  initialCountry = 'us',
  isCountrySearchable = true,
  localization = undefined,
  name,
  onBlur = () => {},
  onChange = () => {},
  onlyCountries = undefined,
  required = false,
  title = 'Phone number',
  topCountriesIsoCodes = [],
  value,
  placeholder = '',
  searchPlaceholder = '',
  searchNotFound = '',
  innerRef = null,
  addFlagSeparator = false,
  scrollToInputOnOpen = false
}) => {
  const containerClassnames = cx('react-tel-input', 'form-phone-input', {
    'form-phone-input--with-error': hasError,
    'form-phone-input--disabled': disabled
  });

  const buttonClassnames = cx('form-phone-input__flag-button', {
    'form-phone-input__flag-button--with-separator': addFlagSeparator
  });

  const inputClassnames = cx('form-phone-input__input', {
    'form-phone-input__input--disabled': disabled
  });

  const titleClassnames = cx('form-phone-input__title', {
    'form-input__title--with-error': hasError
  });

  const handleChange = phoneNumber => {
    onChange({ value: phoneNumber, name });
  };

  return (
    <div className={styles['form-phone-input__wrapper']} ref={innerRef}>
      <NoSSR>
        <Suspense
          fallback={
            <FormInput
              placeholder={placeholder}
              inputClass={inputClassnames}
              title={title}
              required={required}
              value={value}
            />
          }
        >
          <label htmlFor={id} className={titleClassnames}>
            {title.toUpperCase()}
            {required ? '*' : null}
          </label>
          <LazyPhoneInput
            copyNumbersOnly
            autoFormat={autoFormat}
            enableSearch={isCountrySearchable}
            searchPlaceholder={searchPlaceholder}
            searchNotFound={searchNotFound}
            localization={localization}
            preferredCountries={topCountriesIsoCodes}
            containerClass={containerClassnames}
            inputClass={inputClassnames}
            buttonClass={buttonClassnames}
            country={initialCountry}
            disableInitialCountryGuess={disableInitialCountryGuess}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            onlyCountries={onlyCountries}
            dataTest={dataTest}
            disabled={disabled}
            disableDropdown={disableDropdown}
            disableSearchIcon={disableSearchIcon}
            inputProps={{
              id,
              name
            }}
            scrollToInputOnOpen={scrollToInputOnOpen}
          />
        </Suspense>
      </NoSSR>
    </div>
  );
};

FormPhoneInput.propTypes = {
  autoFormat: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  disableInitialCountryGuess: PropTypes.bool,
  disableSearchIcon: PropTypes.bool,
  required: PropTypes.bool,
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  topCountriesIsoCodes: PropTypes.arrayOf(PropTypes.string),
  initialCountry: PropTypes.string,
  localization: PropTypes.shape({
    [PropTypes.string]: PropTypes.string
  }),
  isCountrySearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchNotFound: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
  addFlagSeparator: PropTypes.bool,
  scrollToInputOnOpen: PropTypes.bool
};

export default FormPhoneInput;
